<template>
  <div class="manage-session themev2" v-if="session">

    <div style="display: flex; gap: 20px; align-items: baseline;">
      <h2>Session preparation: {{session.id}} - {{session.name}} </h2>
      <span style="color:#777;">{{session.ownerId ? ownerName : 'Session has no owner'}}, {{session.startDate ? new Date(session.startDate).toLocaleString() : 'No date'}}</span>
    </div>
    <div class="formerror" v-if="error">{{error}}</div> 

    <div class="steps">
      <div class="step" :class="{active: step == 1}" @click="step = 1;">
        <span>1</span> Assign devices
      </div>
      <div class="step" :class="{active: step == 2}"  @click="step = 2;">
        <span>2</span> {{$store.getters.avrisyncMvp ? 'Sync' : 'Transfer'}} content
      </div>
      <!-- <div v-if="env != 'prod'" class="avrisync">
        [dev only] AvriSync: <ToggleButton title="Toggle AvriSync" :disabled="loading" :labels="false" v-model="session.sync" :color="{checked: '#fff', unchecked: '#fff', disabled: '#ccc'}" :switch-color="{checked: '#57af80', unchecked: '#777'}" :sync="true" @change="setSync" /> 
      </div> -->
    </div>

    <div class="tabs" :style="`height: calc(100lvh - var(--size-header) - var(--main-offset-vertical))`">
      <div class="usertabs" v-show="step == 1">
        <div class="usertab">
          <div style="display:flex;">
            <div style="flex-grow:1;white-space: nowrap;padding-right: 15px;">
              <b>Devices</b>  Available - {{availableTechnicalUsers.length}}<span style="padding-left:10px;"></span> Requested - {{session.userCount}}
              <img v-if="session.userCount > availableTechnicalUsers.length" src="../assets/alert_orange.svg" style="margin-left:5px;width:20px;vertical-align: text-top;">
            </div>
            <input v-model="userSearchTerm" type="text" :disabled="loading" autocomplete="off" placeholder="Search available devices..." /> 
          </div>
          <div v-if="$store.getters.isAdmin" style="display:flex; margin-bottom:10px;">
            <select  class="select-css" v-model="orgId" style="width: 200px;">
              <option :value=-1>No organization</option>
              <option v-for="org in organizations" v-bind:value="org.id" :key="org.id" :selected="org.id == $store.getters.organizationId">
                {{org.name}}
              </option>
            </select>
            <span v-if="$refs.usersdatatable" style="color:#777; padding-top: 5px; padding-left: 10px;">{{$refs.usersdatatable.selectedRows.length}} selected</span>
          </div>
          <div v-else-if="$refs.usersdatatable" style="color:#777; padding-bottom:8px;">{{$refs.usersdatatable.selectedRows.length}} selected</div>
          <VueGoodTable 
            ref="usersdatatable"
            class="usersdatatable"
            :columns="$store.getters.avrisyncMvp ? availableDeviceColumns : sessionDeviceColumns"
            :rows="availableTechnicalUsers"
            striped
            @on-selected-rows-change="selectionChanged"
            :max-height="`calc(100lvh - var(--size-header) - var(--bottom-offset-vertical) ${$store.getters.isAdmin ? ' - 16px' : ''})`"
            :fixed-header="true"
            styleClass="vgt-table"
            :sort-options="{
              enabled: true,
              multipleColumns: true,
              initialSortBy: $store.getters.avrisyncMvp ? 
              [ 
                {field: 'contentStatus', type: 'asc'},
                {field: 'firstName', type: 'asc'}
              ]
              : [{field: 'firstName', type: 'asc'}]
            }"
            :search-options="{
              enabled: false,
              externalQuery: userSearchTerm
            }"
            :select-options="{ 
              enabled: true,
              selectOnCheckboxOnly: false,
              disableSelectInfo: true
            }"
            :row-style-class="rowStyleClassFn1">
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'contentStatus'">
                  <!-- {{props.row.sameExperience ? '✓' : ''}} -->
                  <span :class="'cont ' + formatContentStatus(props.row)"></span> 
                  <div v-if="props.row.contentStatus == 2" class="tooltiptext" style="text-align:left;">
                    This device will not have enough storage space for this experience
                  </div>                 
                </span>       
                <span v-else-if="props.column.field == 'timeOverlap'">
                  {{props.row.timeOverlap ? 'Overlapping session' : ''}}
                </span>           
                <span v-else>
                  {{props.formattedRow[props.column.field]}}
                </span>
              </template>
          </VueGoodTable>
        </div>
        <div class="middletab">
          <button class="icon" @click="removeUsers" :disabled="loading || removeDisabled"><img src="../assets/slide_r.svg"/></button>
          <button class="icon" @click="addUsers" :disabled="loading || addDisabled"><img src="../assets/slide_l.svg"/></button>          
        </div>
        <div class="usertab">
          <div style="display:flex;">
            <b>Assigned - {{sessionUsers.length}}</b>            
            <input v-model="sessionUserSearchTerm" type="text" :disabled="loading" autocomplete="off" placeholder="Search devices in session..." /> 
          </div>
          <div v-if="$refs.sessionusersdatatable" style="color:#777; padding-bottom:8px;">{{$refs.sessionusersdatatable.selectedRows.length}} selected</div>
          <VueGoodTable
            ref="sessionusersdatatable"
            :columns="sessionDeviceColumns"
            :rows="sessionUsers"            
            @on-selected-rows-change="selectionChanged"
            @on-row-click="sessionUsersRowClick"
            @on-select-all="onSelectAllSessionUsers"
            :max-height="`calc(100lvh - var(--size-header) - var(--bottom-offset-vertical))`"
            :fixed-header="false"
            styleClass="vgt-table"
            :sort-options="{
              enabled: true,
              initialSortBy: {field: 'firstName', type: 'asc'}
            }"
            :search-options="{
              enabled: false,
              externalQuery: sessionUserSearchTerm
            }"
            :select-options="{ 
              enabled: true,
              selectOnCheckboxOnly: false,
              disableSelectInfo: true 
            }">
              <!-- <template v-slot:selected-row-actions>  
                <button style="height:35px;" class="autowidth right" :disabled="loading" @click="removeUsers">Remove from session</button>                   
              </template> -->
              <template slot="table-row" slot-scope="props">
                <!-- <span v-if="props.column.field == 'sameExperience'">
                  {{props.row.sameExperience ? '✓' : ''}}
                </span>       
                <span v-else-if="props.column.field == 'timeOverlap'">
                  {{props.row.timeOverlap ? '⚠' : ''}}
                </span>            -->
                <span>
                  {{props.formattedRow[props.column.field]}}
                </span>
              </template>
          </VueGoodTable>
        </div>
      </div>

      <div class="usertabs" v-show="step == 2" style="height: 98%;">
        <div v-if="!$store.getters.avrisyncMvp" class="usertab deploysteps">
          <h2>Deployment steps</h2>          
          <div style="display: flex; gap: 12px; align-items: center; margin: 27px 0;">
            <img src="../assets/connected.svg"/> <div>Make sure devices are connected to the Internet and to a power source. </div>
          </div>
          <hr>
          <div style="display: flex; gap: 12px; align-items: center; justify-content:space-between; margin: 27px 0;">
            <div style="display: flex; gap: 12px; align-items: center;">
              <img src="../assets/delete_device.svg"/> 
              <div>
                Delete device content <span style="color:#777;">(optional)</span>
                <br>
                This will remove all files from the selected devices.
              </div>
            </div>
            <button class="white" style="height:30px;" @click="deleteModal = true;" :disabled="loading || !selectedDevices || selectedDevices.length == 0">Delete</button>
          </div>
          <hr>
          <div style="display: flex; gap: 12px; align-items: center; justify-content:space-between; margin: 27px 0;">
            <div style="display: flex; gap: 12px; align-items: center;"><img src="../assets/download.svg"/> <div>Start transferring content.<br><span style="color:#777;">Total size of media is: {{humanFileSize(packageSize)}}</span></div></div>
            <button class="white" style="height:30px;" @click="transferModal = true;" :disabled="manualTransfer || !fileTransferJobId || loading || !selectedDevices || selectedDevices.length == 0">Transfer</button>
          </div>    
          <div style="display: flex; gap: 12px; align-items: center; justify-content:space-between; margin: 27px 0;">
            <div style="display: flex; gap: 12px; align-items: center;">
              <img src="../assets/tap.svg"/> 
              <div>Manually transfer
                <div>
                  <input type="checkbox" v-model="manualTransfer" style="color:#777; vertical-align:middle; width:16px; height:16px; margin-left:0;" /> <span style="color:#777; vertical-align:middle;">I'm transferring manually</span>
                </div>
              </div>
            </div>            
          </div>       
        </div>
        <div v-else style="display: flex; flex-direction: column; align-items: flex-start; justify-content: space-between; max-width: 280px;">          
          <button style="margin-top:30px; min-width: 160px;" :title="session.sync ? 'AvriSync already enabled for this session' : 'Enable AvriSync'" :disabled="loading || session.sync" @click="setSync(true)">{{session.sync ? 'Sync enabled' : 'Sync Now'}}</button> 
          <div v-if="session.sync && session.syncPaused">
            <img src="../assets/frame-9368.svg" style="max-width: 300px;" />
          </div>
          <div style="color: #797d7e; line-height: 1.36;">
            <span style="color:black; font-weight: bold;">Note</span>
            <br>
            You can close this page while the sync process is running.
            <br>
            The process will continue in the background.
          </div>
        </div>
        <div class="middletab">
          <div style="background-color: #d5d5d6; width:1px;height:100%; flex-basis:"></div>
        </div>        
        <div class="usertab">
          <h2 style="position:relative;">{{$store.getters.avrisyncMvp ? 'Sync' : 'Deployment'}} status
            <span v-if="loading" class="spinner" style="position:absolute; left: 180px; top: 2px; margin: 0;"></span>
            <span v-if="mdmloading" class="spinner" style="position:absolute; left: 180px; top: 2px; margin: 0; padding-left: 30px; font-size: 12px; color: #bbb;">Refreshing...</span>
          </h2>
          <div style="display:flex; margin-bottom:15px;">
            <div style="flex-grow:1; white-space:nowrap;padding-right:20px; padding-top:5px;">
              <img src="../assets/mask.svg" style="width:20px; height:20px; vertical-align:middle;" /><span style="vertical-align:middle; padding:0 15px 0 5px;"> <b style="font-size:16px; padding:0;">{{sessionUsers.length}}</b> Assigned</span>
              <img src="../assets/approved.svg" style="width:20px; height:20px; vertical-align:middle;" /><span style="vertical-align:middle; padding:0 15px 0 5px;"> <b style="font-size:16px; padding:0;">{{deviceReadyCount}}</b> Ready</span>
              <img src="../assets/faild.svg" style="width:20px; height:20px; vertical-align:middle;" /><span style="vertical-align:middle; padding:0 0 0 5px;"> <b style="font-size:16px; padding:0;">{{sessionUsers.length - deviceReadyCount}}</b> content pending</span>
            </div>
            <input v-model="deviceSearchTerm" type="text" :disabled="loading" autocomplete="off" placeholder="Search devices..." />             
          </div>
          <VueGoodTable                    
            ref="devicesdatatable"
            class="devicesdatatable"
            :class="{manual: manualTransfer}"
            :columns="deviceColumns"
            :rows="devices"
            :row-style-class="rowStyleClassFn2"            
            @on-selected-rows-change="devicesSelectionChanged"
            @on-row-click="devicesRowClick"
            @on-select-all="onSelectAllDevices"
            :max-height="`calc(100lvh - var(--size-header) - var(--bottom-offset-vertical) - ${$store.getters.avrisyncMvp ? '20': '30'}px)`"
            :fixed-header="false"
            styleClass="vgt-table"
            :sort-options="{
              enabled: true,
              initialSortBy: {field: 'name', type: 'asc'}
            }"
            :search-options="{
              enabled: false,
              externalQuery: deviceSearchTerm,
              searchFn: deviceSearchFn
            }"            
            :select-options="{ 
              enabled: !$store.getters.avrisyncMvp,
              selectOnCheckboxOnly: true,
              disableSelectInfo: true  
            }">
              <!-- <template v-slot:selected-row-actions>  
                <button style="height:35px;" class="autowidth right" :disabled="loading" @click="addUsers">Add to session</button>                   
              </template> -->
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'actions'" class="rowActions">
                  ?
                </span>
                <span v-else-if="props.column.field == 'name'" :title="props.row.serial ? `SN: ${props.row.serial}` : ''">
                  {{props.row.name}}
                </span>
                <template v-else-if="props.column.field == 'mdm'">                  
                  <span v-if="!props.row.mdmId" style="color:red; font-weight:bold;">{{props.row.deviceName ? 'No MDM' : 'Device/User Unlinked'}}</span>                  
                  <span v-else class="connection" :title="`MDM Id: ${props.row.mdmId}`">{{props.row.mdmStatus ? props.row.mdmStatus.connectionStatus : '--'}}</span>
                </template>
                <span v-else-if="props.column.field == 'mdmPower' && props.row.mdmStatus" class="power" :class="getBatteryClass(props.row.mdmStatus)">
                  {{`${props.row.mdmStatus.battery}%`}}
                </span>
                <span v-else-if="props.column.field == 'storage' && props.row.mdmStatus" class="storage" :class="{warning: packageSize > props.row.mdmStatus.freeStorage}">
                  {{props.row.mdmStatus ? `${(props.row.mdmStatus.freeStorage/(1000000000)).toFixed(1)}/${(props.row.mdmStatus.storage/(1000000000)).toFixed(1)} GB` : '--'}}
                </span>
                <span v-else-if="props.column.field == 'mdmJob'">
                  {{props.row.jobStatus ? props.row.jobStatus.status : '--'}}
                </span>
                <span v-else-if="props.column.field == 'syncStatus'" class="tooltip white" :class="session.sync ? `sync ${props.row.syncStatus}` : ''" :title="session.sync ? '' : 'Sync not started. Click \'Sync Now\' to begin.'">   
                  <span v-if="!session.sync" style="color: #ccc;">Sync Off</span>                      
                  <div v-else-if="session.sync || (props.row.syncPaused && props.row.syncStatus == 'paused')" class="tooltiptext" style="text-align:left; width: 252px; white-space: normal;">                      
                      Sync is paused for this device due to an upcoming or active session.  Sync will resume automatically 2 hours after the session ends.
                  </div> 
                  <span v-if="session.sync" class="progress">{{props.row.progress}}% </span>                           
                </span>
                <span v-else-if="props.column.field == 'fileStatus'" class="files" :class="{ready: session.fileList && session.fileList.length == props.row.correctFiles.length}" :title="props.row.deviceName ? 'Click for file details' : ''" @click="fileDetails(props.row)">
                  {{`${props.row.correctFiles.length}/${session.fileList ? session.fileList.length : 0}`}}
                </span>
                <span v-else>
                  {{props.formattedRow[props.column.field]}}
                </span>
              </template>
          </VueGoodTable>

          <div v-if="step == 2" style="padding-top:8px; color: #a2a2a2;">
            The {{$store.getters.avrisyncMvp ? 'sync' : 'transfer'}} status refreshes automatically every few minutes.
          </div>
        </div>
      </div>

      

      <div class="buttonsrow" v-if="step == 1">
        <span v-if="loading" class="spinner" style="position: static; display: block; margin: 10px 0 0 0;"></span>
        <button class="white" @click="$store.getters.avrisyncMvp ? leaveSyncModal = true : $emit('close')" :disabled="loading">Close</button>
        <button @click="step = 2" :disabled="loading">
          Next
          <img src="../assets/arrow_right_white.svg" style="height:65%; transform: translate(5px, 3px);"/>
        </button>
      </div>
      <div class="buttonsrow" v-else>
        <button class="white" @click="$store.getters.avrisyncMvp ? leaveSyncModal = true : $emit('close')">Close</button>
        <button class="white" @click="step = 1">
          <img src="../assets/arrow_r.svg" style="height:65%; transform: translate(-3px, 2px) rotate(180deg);"/>
          Back
        </button>
        <button @click="markReadyModal = true;" v-if="session.workflow != 4" :disabled="loading">Mark session as Ready</button>
      </div>
    </div>

    <Modal2 v-if="transferModal" @close="transferModal = false;" class="center">      
      <h3 slot="header"><img src="../assets/download.svg" style="width: 40px; margin-right:12px; vertical-align:middle;" />Start file transfer</h3>
      <div slot="body">
        After file transfer started, wait until all devices reach status of either DEPLOYED or ERROR/FAILED.
        <br>
        <br>
        Starting another transfer will not cancel any previous attempts that are still scheduled or in progress, and may make devices unresponsive.        
        <div v-if="session.fileTransferJobApplied">
          <br>
          Last transfer started: <b>{{ new Date(session.fileTransferJobApplied).toLocaleString() }}</b>
        </div>
        <div v-if="devicesAlreadyApplied.length > 0">
          <br>
          Transfer was already started previously for <b>{{devicesAlreadyApplied.length}}</b> of the devices.
        </div>
        <div v-if="devicesPending.length > 0">
          <br>
          Transfer still pending for <b>{{devicesPending.length}}</b> of the selected devices.
        </div>     
        <div v-if="devicesPendingOther.length > 0">
          <br>
          <b>{{devicesPendingOther.length}}</b> of the selected devices have other jobs pending in their queue.
        </div>     
      </div>
      <div slot="footer">       
        <button class="right" @click="applyFileTransferJob(); transferModal = false;" :disabled="loading">Start transfer for selected devices</button>   
        <button class="right white" @click="transferModal = false" :disabled="loading">Cancel</button>            
      </div>
    </Modal2>

    <Modal2 v-if="deleteModal" @close="deleteModal = false;" class="center">      
      <h3 slot="header"><img src="../assets/delete_device.svg" style="width: 40px; margin-right:12px; vertical-align:middle;" />Delete content on devices</h3>
      <div slot="body">
        After content deletion will be started on devices, please wait a few minutes before pushing any new content!     
        <div v-if="devicesPending.length > 0">
          <br>
          Transfer still pending for <b>{{devicesPending.length}}</b> of the selected devices.
        </div>     
        <div v-if="devicesPendingOther.length > 0">
          <br>
          <b>{{devicesPendingOther.length}}</b> of the selected devices have other jobs pending in their queue.
        </div>        
      </div>
      <div slot="footer">       
        <button class="right" @click="applyEmptyFolderJob(); deleteModal = false;" :disabled="loading">Delete all content on selected devices</button>   
        <button class="right white" @click="deleteModal = false" :disabled="loading">Cancel</button>            
      </div>
    </Modal2>

    <Modal2 v-if="deviceErrorsModal" @close="deviceErrorsModal = false;">      
      <h3 slot="header">Content issues for device: {{tempDevice.deviceName}}</h3>
      <div slot="body">
        <table v-if="tempDevice.brokenFiles.length > 0">
            <tr style="text-align:left;">
              <th>File</th>
              <th>Server Date</th>
              <th>Device Date</th>
              <th>Reason</th>
            </tr>                      
            <tr v-for="file in tempDevice.brokenFiles" :key="file.key">                
              <td>{{file.key}}</td>
              <td>{{file.serverLastModified}}</td>
              <td>{{file.lastModified ? file.lastModified : ''}}</td>
              <td>{{file.reason}}</td>
            </tr>         
        </table> 
        <div v-else>
          Today is a good day, all content is fine on this device 
          <br>
          (• ◡•)
        </div>
      </div>
      <div slot="footer">               
        <button class="insead white" @click="deviceErrorsModal = false">Close</button>              
        <!-- <button class="insead" @click="exportDeviceErrors">Download CSV</button> -->
      </div>
    </Modal2>  

    <Modal2 v-if="markReadyModal" @close="markReadyModal = false;" class="center">      
      <h3 slot="header">Mark session as Ready</h3>
      <div slot="body">
        After marking a session as ready, you can still come back to this screen. For Professor it will be shown as a fulfilled request and they can start teaching.          
        <div v-if="sessionUsers.length != deviceReadyCount">
          <br>
          <img src="../assets/alert_orange.svg" style="vertical-align:middle; margin-right:10px;" /> <span style="vertical-align:middle;"><b>{{sessionUsers.length - deviceReadyCount}}</b> devices don't have all the required content.</span>
          <br>
          <br>
          You can still mark it ready, but <i>those devices won't be functional</i>. 
          <br>
          <br>
          If this is intentional, consider removing those devices from the session instead.
        </div>        
      </div>
      <div slot="footer">       
        <button class="right" @click="markReady(); markReadyModal = false;" :disabled="loading">Confirm</button>   
        <button class="right white" @click="markReadyModal = false" :disabled="loading">Cancel</button>            
      </div>
    </Modal2>

    <Modal2 v-if="leaveSyncModal" @close="leaveSyncModal = false;" class="center">    
      <h3 slot="header">
        <img v-if="session.sync" src="../assets/download.svg" style="width: 24px; margin-right:12px; vertical-align:middle;" /> 
        <img v-else src="../assets/alert.svg" style="width: 24px; margin-right:12px; vertical-align:middle;" /> 
        Leaving This Page
      </h3>  
      <div slot="body">
        {{
          session.sync
           ? 'Your files are currently syncing in the background. You can safely navigate away from this screen, and the sync process will continue uninterrupted. You may return to this screen at any time to check the progress.'
           : 'You\'re about to close this page. The devices are assigned to your session without syncing. Come back later to sync it.'
        }}
      </div>     
      <div slot="footer">       
        <button class="right" @click="$emit('close'); leaveSyncModal = false;" :disabled="loading">Leave</button>   
        <button class="right white" @click="leaveSyncModal = false" :disabled="loading">Cancel</button>            
      </div> 
    </Modal2>

    <Snackbar ref="snackbar" /> 

  </div>
</template>

<script>
import axios from 'axios'
import Modal2 from '@/components/Modal2.vue'
import { VueGoodTable } from 'vue-good-table'
//import _ from 'lodash'
import { humanFileSize } from '@/utils.js'
import Snackbar from '@/components/Snackbar.vue'

export default {
  name: 'ManageSession', 
  props: {
    organizations: Array,
    userList: Array,
    experience: Object,
    sessionId: Number    
  },
  components: {
    Modal2,
    Snackbar,
    VueGoodTable
  },
  data: function(){
    return{      
      session: undefined,
      error: undefined,
      loading: false,
      mdmloading: false,
      internalUserList: [],
      userSearchTerm: undefined,
      sessionUserSearchTerm: undefined,
      deviceSearchTerm: undefined,
      manualTransfer: false,
      transferModal: false,
      deleteModal: false,
      deviceErrorsModal: false,
      markReadyModal: false,
      availableDeviceColumns: [
        {
          label: 'Name',
          field: 'firstName',
        },
        {
          label: 'Content Status',
          field: 'contentStatus'
        },
        {
          label: 'Scheduling conflict',
          field: 'timeOverlap',
        }
      ],
      sessionDeviceColumns: [        
        {
          label: 'Name',
          field: 'firstName',
        }        
      ],      
      sessionUsers: [],
      devices: [],
      step: 1,
      removeDisabled: true,
      addDisabled: true,      
      mdmjobstatus: [],      
      tickerInterval: null,
      tempDevice: undefined,
      orgId: this.$store.getters.organizationId ?? -1,
      ownerName: null,
      leaveSyncModal: false,
      firstLoad: true      
    }
  },
  watch:{
    orgId: async function(){
      await this.fetchUsers()
    }   
  },
  computed: {
    deviceColumns(){
      return [        
        {
          label: 'Name',
          field: 'name',
          sortable: true
        },
        {
          label: 'Connection',
          field: 'mdm',
          sortable: false
        },
        {
          label: 'Power',
          field: 'mdmPower',
          sortable: false
        },
        {
          label: 'Free Space',
          field: 'storage',
          sortable: false,
          hidden: this.$store.getters.avrisyncMvp
        },
        {
          label: 'Transfer',
          field: 'mdmJob',
          sortable: false,
          hidden: this.$store.getters.avrisyncMvp
        },
        {
          label: 'Sync status',
          field: 'syncStatus',
          sortable: true,
          hidden: !this.$store.getters.avrisyncMvp
        },
        {
          label: 'Files',
          field: 'fileStatus',
          sortable: false
        },
        // {
        //   label: 'Actions',
        //   field: 'actions'
        // }
      ]//.filter(c => this.mvp ? (c.field != 'storage' && c.field != 'mdmJob') : (c.field != 'syncStatus'))
    },
    env(){
      return process.env.VUE_APP_ENV
    },
    deviceReadyCount(){      
      return this.devices.filter(d => d.brokenFiles.length == 0).length
    },
    selectedDevices(){     
      return this.$refs.devicesdatatable?.selectedRows ?? []
    },
    devicesAlreadyApplied(){
      return this.selectedDevices.filter(d => d.jobStatus) ?? []
    },
    devicesPending(){
      return this.devicesAlreadyApplied?.filter(d => ['SCHEDULED','INPROGRESS','PENDING'].includes(d.jobStatus.status)) ?? []
    },
    devicesPendingOther(){    
      return this.$refs.devicesdatatable?.selectedRows?.filter(d => d.deviceJobs && d.deviceJobs.find(j => j.jobID != this.fileTransferJobId && ['SCHEDULED','INPROGRESS','PENDING'].includes(j.status))) ?? []
    },
    mdmAccount(){
      return this.session.organizationId && this.session.organizationId == process.env.VUE_APP_INSEAD_ORG_ID ? "Insead" : "Avris";
    },
    fileTransferJobId(){
      if(!this.session.fileTransferJobId)
        return null
      return !this.session.fileTransferJobId.includes(',') && !this.session.fileTransferJobId.includes('|')
                ? this.session.fileTransferJobId
                : this.session.fileTransferJobId.split(',').find(t => t.startsWith(this.mdmAccount))?.split('|')[1];
    },
    packageSize(){
      return this.session.fileList?.reduce(( previousValue, currentValue ) => previousValue + currentValue.size, 0) ?? 0      
    },    
    users(){
      //return []
      return this.userList ?? this.internalUserList
    },
    availableTechnicalUsers(){  
      //return []
      const sessionUserIds = this.sessionUsers.map(u => u.id)
      return this.users.filter(user => !sessionUserIds.includes(user.id))      
    },
    pathPrefixes(){ 
      let tmp = JSON.parse(this.session.structure ?? this.session.experience.structure)
      return { 
        webContentRootPath: this.session.webRoot ?? tmp.webContentRootPath,
        deviceRootPath: '/VRData/' + tmp.menuFile.split('/')[0] + '/'
      }
    }   
  },
  methods: {    
    devicesRowClick(params) {
      // params.row - row object 
      // params.pageIndex - index of this row on the current page.
      // params.selected - if selection is enabled this argument 
      // indicates selected or not
      // params.event - click event
      let d = this.devices.find(d => d.serial == params.row.serial)
      d.vgtSelected = params.row.vgtSelected  
      /* 
        this is a workaround of a bug in vuegoodtable: ticking the checkbox does not update vgtSelected on original object, only reflected here in the params (and tracks it internally somehow)
        but to be able to set it programmatically, we have to set it on the original object
        this event handler here synchronizes the two
      */
      // console.log(params)
      // console.log('params vgtSelected: ' + params.row.vgtSelected + ' devices vgtSelected: ' + d.vgtSelected)
    },
    sessionUsersRowClick(params) {
      let d = this.sessionUsers.find(d => d.id == params.row.id)
      d.vgtSelected = params.row.vgtSelected 
      // console.log(params)
      // console.log('params vgtSelected: ' + params.row.vgtSelected + ' sessionusers vgtSelected: ' + d.vgtSelected)
    },
    computeDeviceStatuses(){
      this.devices = this.sessionUsers.map(su => {
        let deviceJobStatus = this.mdmjobstatus.find(s => s.device.id == su.device?.id)
        let deviceFileList = su.device?.fileList?.filter(f => f.key.startsWith(this.pathPrefixes.deviceRootPath))
        let tmp = {
          name: su.firstName,
          deviceName: su.device?.name,
          deviceLastSeen: su.device?.lastSeen,
          serial: su.device?.id,
          mdmId: su.device?.mdmId,
          mdmAccount: su.device?.mdmAccount,
          mdmStatus: su.device?.mdmStatus,          
          jobStatus: deviceJobStatus?.jobs.find(j => j.jobID == this.fileTransferJobId),
          deviceJobs: deviceJobStatus?.jobs,
          correctFiles: [],
          brokenFiles: [],
          syncError: false,
          syncStatus: null,
          syncPaused: su.syncPaused,
          networkStatus: su.device?.networkStatus,
          date: su.date,
          progress: su.progress         
        }
        tmp.vgtDisabled = !tmp.mdmId || !tmp.deviceName // no mdm, or no device linked to user at all            
        if(this.firstLoad)    
          tmp.vgtSelected = !tmp.vgtDisabled && su.device?.mdmId && !tmp.jobStatus  // initially select all that didn't have the job applied yet
        else{
          let previous = this.devices.find(d => d.serial == tmp.serial)          
          tmp.vgtSelected = previous?.vgtSelected ? true : false  // otherwise (during refresh) keep previous selection          
        }
          
        // compute file integrity
        // TODO maybe add timestamp on API and only compute if timestamp has changed compared to previous
        this.session.fileList?.forEach(sessionFile =>{
          let fileKey = sessionFile.key.substring(this.pathPrefixes.webContentRootPath.length)          
          let deviceFile = deviceFileList?.find(f => f.key == `${this.pathPrefixes.deviceRootPath}${fileKey}`)
          if(deviceFile && deviceFile.size == sessionFile.size)
            tmp.correctFiles.push(deviceFile)
          else{
            tmp.brokenFiles.push({
              key: fileKey,
              size: deviceFile?.size,
              serverLastModified: sessionFile.lastModified,
              lastModified: deviceFile?.lastModified,
              reason: !deviceFile 
                ? 'missing'
                : `${deviceFile.size} instead of ${sessionFile.size} bytes`
            })
          }  
          if(deviceFile?.error)
            tmp.syncError = true
        })
        tmp.syncStatus = this.formatSyncStatus(tmp) // must come after file status aggregation
        return tmp
      })
    },
    formatContentStatus(u){      
        if(!u.deviceId) return 'nodevice'        
        else if(u.deviceFreeStorage == null) return 'noinfo'
        else if(u.sameExperience) return 'ready'
        else if(u.deviceFreeStorage <= this.packageSize) return 'nospace'        
        else return ''      
    },
    formatContentStatusNumerical(u){
      if(!u.deviceId) return 4       
      else if(u.deviceFreeStorage == null) return 3
      else if(u.sameExperience) return 0
      else if(u.deviceFreeStorage <= this.packageSize) return 2        
      else return 1  
    },    
    formatSyncStatus(u){      
        if (u.syncPaused) return 'paused'
        else if(u.syncError) return 'error'
        else if (!u.deviceLastSeen || u.date > u.deviceLastSeen) return 'pending'        
        else if(u.correctFiles.length == this.session.fileList?.length ) return 'ready'
        else return 'syncing' + (u.networkStatus ? ' badconn' : '')      
    },
    async fetchOwnerDetails(){
      if(this.session?.ownerId){
        let resp = await axios.get(`users/${this.session.ownerId}`)
        if(resp.data)
          this.ownerName = `${resp.data.firstName} ${resp.data.lastName}`
      }
    },
    async setSync(on){
      try{
        this.loading = true
        this.$nprogress.start()    
        await axios.post(`sessions/${this.session.id}/sync?enable=${on}`)
        this.session.sync = on
        this.$refs.snackbar.show(`AvriSync has been ${on ? 'enabled' : 'disabled'} for this session.`)   
        this.$emit('avrisyncUpdated', this.session)
      }
      catch(err){
        this.error = err?.response?.data ?? err
      }
      finally{
        this.loading = false
        this.$nprogress.done()
      }
    },
    getBatteryClass(mdmStatus){
      if(mdmStatus.charging == 'Charging')
        return 'charging'
      else if (Number(mdmStatus.battery) >= 90)
        return 'high'
      else if (Number(mdmStatus.battery) >= 50)
        return 'medium'
      else
        return 'low'
    },
    deviceSearchFn(row/*, col, cellValue, searchTerm*/){
      return row.name.toLowerCase().includes(this.deviceSearchTerm.toLowerCase())
    },
    fileDetails(device){
      if(!device.deviceName) // cannot show anything for unlinked devices
        return
      this.tempDevice = device
      this.deviceErrorsModal = true
    },
    async markReady(){
      await this.setWorkflow(4)
      this.$emit('close')
    },
    async setWorkflow(status){
      try{
        this.loading = true
        this.$nprogress.start()    
        await axios.post(`sessions/${this.session.id}/setworkflow?status=${status}`)
        this.$emit('updated', status)
      }
      catch(err){
        this.error = err?.response?.data ?? err
      }
      finally{
        this.loading = false
        this.$nprogress.done()
      }
    },
    async applyFileTransferJob(){
      try{
        this.error = undefined
        this.loading = true
        this.mdmloading = true
        await axios({ url: `sessions/${this.session.id}/applymdmjob?job=filetransfer`, method: "POST", data: this.selectedDevices.map(d => d.mdmId) }) 
        this.$refs.snackbar.show('File transfers started on devices. Please do not start another transfer while some devices are in Pending state.', 5000)   
        setTimeout(() => this.refreshMdmJobStatus(), 5000)     
      }
      catch(err){
        this.error = err?.response?.data ?? err
      }
      finally{
        this.loading = false
      }
    },  
    async applyEmptyFolderJob(){
      try{
        this.error = undefined
        this.loading = true
        this.mdmloading = true
        await axios({ url: `sessions/${this.session.id}/applymdmjob?job=emptyfolder`, method: "POST", data: this.selectedDevices.map(d => d.mdmId) }) 
        this.$refs.snackbar.show('Content deletion started on devices. Please wait a few minutes before pushing any new content!', 5000)   
        setTimeout(() => this.refreshMdmJobStatus(), 5000)    
      }
      catch(err){
        this.error = err?.response?.data ?? err
      }
      finally{
        this.loading = false
      }
    }, 
    humanFileSize(size){
      return humanFileSize(size)
    },
    rowStyleClassFn2(row) {
      let classes = row.mdmStatus?.connectionStatus == 'Online' ? 'online' : 'offline'
      if(!row.deviceName)
        classes += ' unlinked'
      if(!row.mdmId)
        classes += ' nomdm'
      return classes
    },
    rowStyleClassFn1(row) {
      let classes = row.contentStatus == 2 ? 'nospace tooltip white' : ''
      return classes
    },
    async refreshMdmJobStatus(mdmJobStatusesResponse){
      try{
        this.error = undefined
        //this.loading = true
        let resp = mdmJobStatusesResponse ?? await axios.get(`sessions/${this.session.id}/mdmjobstatus`)         
        this.mdmjobstatus = resp.data
      }
      catch(err){
        this.error = err?.response?.data ?? err
      }
      finally{
        //this.loading = false
      }
    },
    async refreshMdmDeviceStatus(mdmDeviceStatusesResponse){
      try{
        this.error = undefined
        //this.loading = true
        let resp = mdmDeviceStatusesResponse ?? await axios.get(`sessions/${this.session.id}/mdmdevicestatus`)         
        for (let i = 0; i < resp.data.length; i++) {
          let status = resp.data[i]
          let user = this.sessionUsers.find(u => u.device && u.device.mdmId == status.mdmId)          
          if(user)
            this.$set(user.device, 'mdmStatus', status)
        }            
      }
      catch(err){
        this.error = err?.response?.data ?? err
      }
      finally{
        //this.loading = false
      }
    },
    selectionChanged(){
      this.addDisabled = this.$refs.usersdatatable?.selectedRows?.length == 0
      this.removeDisabled = this.$refs.sessionusersdatatable?.selectedRows?.length == 0
    },
    devicesSelectionChanged(){      
    },
    onSelectAllDevices(params){
      // workaround for https://github.com/xaksis/vue-good-table/issues/848
      // params.selected - whether the select-all checkbox is checked or unchecked
      // params.selectedRows - all rows that are selected (this page)      
      this.devices.forEach(d => d.vgtSelected = params.selected)
    },   
    onSelectAllSessionUsers(params){  
      this.sessionUsers.forEach(d => d.vgtSelected = params.selected)
    },   
    async addUsers(){                 
      try{
        this.error = undefined;
        this.$nprogress.start();
        this.loading = true;

        let ids = this.$refs.usersdatatable.selectedRows.map(u => u.id)        
        await axios({ url: `sessions/${this.session.id}/users`, data: ids, method: 'POST' })
        await this.fetchUsers()
        this.computeDeviceStatuses()
      }
      catch(err){
        console.log("Error when adding users to session: " + (err?.response?.data ? err.response.data : err));
        this.error = (err?.response?.data ? err.response.data : err);
      }
      finally{
        this.loading = false;
        this.$nprogress.done();
      }      
    },
    async removeUsers(){ 
      try{
        this.error = undefined;
        this.$nprogress.start();
        this.loading = true;

        let ids = this.$refs.sessionusersdatatable.selectedRows.map(u => u.id)        
        await axios({ url: `sessions/${this.session.id}/users`, data: ids, method: 'DELETE' })
        await this.fetchUsers()  
        this.computeDeviceStatuses()      
      }
      catch(err){
        console.log("Error when removing users from session: " + (err?.response?.data ? err.response.data : err));
        this.error = (err?.response?.data ? err.response.data : err);
      }
      finally{
        this.loading = false;
        this.$nprogress.done();
      } 
    },
    async fetchUsers(){
      await Promise.all([
        (async () => {          
          let tmp = (await axios({ url: `users/technical?experienceId=${this.session.experienceId}&sessionId=${this.session.id}&orgId=${this.orgId}`, method: "GET" })).data
          tmp.forEach(u => {
            u.contentStatus = this.formatContentStatusNumerical(u)
            if(u.contentStatus == 2){
              u.vgtDisabled = true          
            }        
          })
          this.internalUserList = tmp    
        })(),  
        (async () => {
          let resp = await axios({ url: `sessions/${this.session.id}/users` })       
          this.sessionUsers = resp.data   
        })()
      ])
    },
    async refreshMdm(mdmOnly){
      try{
        this.mdmloading = true

        await Promise.all([
          (async () => {
            // file list
            if(!mdmOnly){              
              const resp = await axios({ url: `sessions/${this.session.id}/users` })
              for (let index = 0; index < resp.data.length; index++) {
                const element = this.sessionUsers.find(su => su.id == resp.data[index].id)                 
                if(element?.device){
                  this.$set(element, 'syncPaused', resp.data[index].syncPaused)
                  this.$set(element, 'progress', resp.data[index].progress)
                  this.$set(element.device, 'fileList', resp.data[index].device.fileList)
                  this.$set(element.device, 'lastSeen', resp.data[index].device.lastSeen)
                  this.$set(element.device, 'networkStatus', resp.data[index].device.networkStatus)
                }
              }
            }
          })(),        
          // mdm info
          this.refreshMdmDeviceStatus(),
          this.refreshMdmJobStatus()    
        ])

        this.computeDeviceStatuses()        
      } 
      finally{
        this.mdmloading = false
      }
    }
  },
  async mounted() {
    try{
      this.$nprogress.start()
      this.loading = true
      this.$store.state.showLoader = true
      let x = await axios.get(`sessions/${this.$route.params?.id ?? this.sessionId}`)  // fetch full session details  
      this.session = x.data  

      const [,,deviceStatusesResponse] = await Promise.all([
        this.fetchUsers(),
        this.refreshMdmJobStatus(),
        axios.get(`sessions/${this.session.id}/mdmdevicestatus`)
      ])

      this.refreshMdmDeviceStatus(deviceStatusesResponse)
      
      this.computeDeviceStatuses() // this works from all previously fetched data
       
      this.fetchOwnerDetails()   // no need to wait for this
    }
    finally{
      this.step = this.sessionUsers.length > 0 ? 2 : 1
      this.loading = false
      this.$nprogress.done()
      this.$store.state.showLoader = false
    }
  },
  created() {
    if(!this.tickerInterval)
      this.tickerInterval = setInterval(() => { this.firstLoad = false; this.refreshMdm(); }, 60000)      
  },
  beforeDestroy: function(){
    if(this.tickerInterval)
      clearInterval(this.tickerInterval)
    this.$store.state.showLoader = false
  }
}
</script>

<style lang="scss">
.manage-session{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: -2px;
  padding: 24px 57px 0 57px;
  background-color: #f4f4f4;
  z-index: 20;
  --main-offset-vertical: 100px;
  --bottom-offset-vertical: 227px;
  
  .steps{
    height: 18px;
    margin: 6px 0 8px 0;

    .step{
      color: #a2a2a2;
      float: left;
      margin-right: 24px;
      cursor: pointer;
      opacity: 0.8;
      &:hover{
        opacity:1;
      }

      span{
        display: inline-block;
        border-radius: 50px;
        border: 1px solid #a2a2a2;
        text-align: center;
        width: 18px;
        height: 18px;
        line-height: 18px;
      }

      &.active{
        color: #4cad82;
        span{
          border: 1px solid #4cad82;
        }
      }
    }

    .avrisync{
      float:right;
      label{
        float: none;
        margin: unset;
      }
    }
  }

  .vgt-inner-wrap{
    box-shadow: none;
  }
  table.vgt-table thead th:not(:first-child){
    padding-left: 8px;
  }
  table.vgt-table thead th{
    background-color: #eef2f5;
  }
  .vgt-table{
    border-radius: 8px;
    border: none;

    tr{
      cursor: unset;
    }
    th, td{
      padding: 0 2px;      
      vertical-align: middle;    
    }
  }

  .tabs{
    padding: 16px;
    border-radius: 8px;
    border: solid 1px #d5d5d6;
    background-color: #fff;
    position: relative;

    span.cont{
      &::before{        
        padding-right:5px;
        content: '⬤';
        color: #4a92ff;
      }
      &::after{
        content: 'Available Space'
      }
      &.ready{
        &::before{
          content: '⬤';
          color: #51d247;
        }
        &::after{
          content: 'Experience Assigned'
        }
      }
      &.nospace{
        &::before{
          content: '⬤';
          color: #ff852d;
        }
        &::after{
          content: 'Insufficient Space'
        }
      }  
      &.noinfo{
        &::before{
          content: '⬤';
          color: #a2a2a2;
        }
        &::after{
          content: 'No storage info yet'
        }
      }  
      &.nodevice{
        &::before{
          content: '⬤';
          color: red;
        }
        &::after{
          content: 'Device not linked*'
        }
      }  
    }

    span.sync{
      .progress{
        display: none;
      }

      &::before{
        content: '⬤';
        padding-right:5px;
      }
      &.ready{
        &::before{
          color: #51d247;
        }
        &::after{
          content: 'Up-to-date'
        }
      }   
      &.syncing{
        &::before{
          color: #4a92ff;
        }
        &::after{
          content: 'Syncing'
        }
        
        &.badconn{
          &::before{
            color: #ff9447;
          }
          &::after{
            content: 'Syncing - Slow Connection'
          }
        }

        .progress{
          display: inline;
        }
      }  
      &.pending{
        &::before{
          color: #a2a2a2;
        }
        &::after{
          content: 'Awaiting Sync'
        }
      }   
      &.error{
        &::before{
          color: #ff4141;
        }
        &::after{
          content: 'Sync Issue'
        }
      }  
      &.paused{
        &::before{
          content: '';
          width: 15px;
          height: 14px;
          display: inline-block;
          background: url(../assets/pause-orange.svg) left no-repeat;
          background-size: contain;
          vertical-align: middle;
        }
        &::after{
          content: 'Paused';
          vertical-align: middle;
        }
      }  
    }

    .usertabs{
      display: flex;
      justify-content: space-between; 

      .middletab{
        flex-basis: 100px;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;

        button.icon{
          width: 30px;
          height: 30px;
        }

        button[disabled]{
          opacity: 0.3;
          background-color: transparent;
          &:hover{
            background-color: transparent;
          }
        }
      }

      .usertab{    
        flex-grow: 1;

        b {
          white-space: nowrap;          
          padding-right: 20px;
          padding-top:2px;
        }

        &.deploysteps{
          img{
            width: 28px;
            height: 28px;
          }
        }
      }
    }

    .buttonsrow{
      margin-top:20px;
      display: flex;
      justify-content: flex-end;
      gap:16px;
      position: absolute;
      bottom: 16px;
      right: 16px;
    }
  }

  .usersdatatable{
    tr.nospace{
      td {
        color: #bbb;
      }
      input {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }

  .devicesdatatable{

    .vgt-responsive{
      overflow-x: hidden;
    }
      
    table.vgt-table{
      font-size: 14px;      
      
      tr{
        white-space: nowrap;
      }

      input{
        height: unset;
      }
    }

    .storage{
      &.warning{
        color:red;
      }
    }

    .files{
      cursor: pointer;
      border-radius: 3px;
      &:hover{
        background-color: #df9090;
        color: white;
      }

      &::before{
        display: inline-block;
        content: ' ';
        width: 12px;
        height:12px;
        background: url(../assets/faild.svg);
        background-size: 12px 12px;
      }

      &.ready{
        &:hover{
          background-color: #86c596;
        }
        &::before{
          background: url(../assets/approved.svg);
          background-size: 12px 12px;
        }
      }
    }

    .connection{
      &::before{
        content:'';
        width:12px;
        height: 12px;
        display: inline-block;
        border-radius: 20px;
        background-color: #4cad82;
        margin-right:9px;
      }
    }
    
    .offline{
      .connection::before{
        background-color: lightgray;
        font-weight: bold;
      }
      .power{
        opacity: 0.3;
      }
    }

    .power{
      &::before{
        content:'';
        width:20px;
        height: 20px;
        display: inline-block;
        background: url(../assets/low-battery.svg) no-repeat;
        vertical-align: middle;
      }

      &.medium::before{
        background: url(../assets/medium-battery.svg) no-repeat;
      }

      &.high::before{
        background: url(../assets/high-battery.svg) no-repeat;
      }

      &.charging::before{
        background: url(../assets/charge-battery.svg) no-repeat;
      }
    }

    .unlinked{
      opacity: 0.3;
      .files{
        cursor: unset;
        &:hover{
          background-color: unset;
          color: unset;
        }
      }
    }

    .unlinked, .nomdm{
      input{
        display: none;
      }
      .vgt-checkbox-col{
        pointer-events:none;
      }
    }    

    &.manual{
      thead th:nth-child(6), tbody td:nth-child(6){
        span{
          opacity: 0.3;
        }
      }
    }
  }

  @media (min-width: 1440px){ 
    --main-offset-vertical: 115px;
    --bottom-offset-vertical: 242px;
    padding: 24px 90px 0 90px;
    .steps{
      height: 20px;
      margin: 6px 0 12px 0;
      .step span{
        width: 20px;
        height: 20px;
        line-height: 20px;
      }
    }
  }

  @media (min-width: 1600px){ 
    --bottom-offset-vertical: 258px;
    --main-offset-vertical: 114px;
    padding: 24px 100px 0 100px;
    .steps{
      height: 22px;
      margin: 6px 0 13px 0;
      .step span{
        width: 22px;
        height: 22px;
        line-height: 22px;
      }
    }   
    .tabs{
      padding: 20px;
      .buttonsrow{
        bottom: 20px;
        right: 20px;
      }
    } 
  }

  @media (min-width: 1920px){ 
    --bottom-offset-vertical: 292px;
    --main-offset-vertical: 134px;
    padding: 24px 120px 0 120px;
    .steps{
      height: 26px;
      margin: 10px 0 16px 0;
      .step{ 
        font-size: 18px;
        span{
          width: 26px;
          height: 26px;
          line-height: 22px;
          border: 2px solid #a2a2a2;
        }
        &.active{
          span{
            border: 2px solid #4cad82;
          }
        }
      }      
    }
  }
}

</style>
